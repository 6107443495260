import React from "react";
import { BrowserView, MobileView } from "react-device-detect";

import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.compat.css";

import { piattiSection, piatti } from "site-structure";

import "./piatti.scss";

const Piatti = () => {
	const renderPiatti = () => {
		return piatti.map((piatto, index) => (
			<div className="col-sm-6 col-md-3 col-lg-3" key={"piatto-" + index}>
				<div className="content-box text-left">
					<BrowserView>
						<ScrollAnimation
							animateIn="fadeInUp"
							delay={100 * index}
							duration={1.2}
							animateOnce={true}>
							<div
								className="content-box-image"
								style={{
									backgroundImage: `url(${piatto.image})`,
									height: 220,
									backgroundSize: "cover",
									backgroundPosition: "50% 50%",
									backgroundRepeat: "no-repeat",
								}}></div>
						</ScrollAnimation>
						{/* <ScrollAnimation
							animateIn="fadeInUp"
							delay={120 * index}
							duration={1.2}
							animateOnce={true}>
							<h3 className="content-box-title font-serif text-left">
								{piatto.name}
							</h3>
						</ScrollAnimation>
						<ScrollAnimation
							animateIn="fadeInUp"
							delay={150 * index}
							duration={1.2}
							animateOnce={true}>
							<div className="text-left">{piatto.description}</div>
						</ScrollAnimation> */}
					</BrowserView>

					<MobileView>
						<ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
							<div
								className="content-box-image"
								style={{
									backgroundImage: `url(${piatto.image})`,
									height: 220,
									backgroundSize: "cover",
									backgroundPosition: "50% 50%",
									backgroundRepeat: "no-repeat",
								}}></div>
						</ScrollAnimation>
						{/* <ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
							<h3 className="content-box-title font-serif text-left">
								{piatto.name}
							</h3>
						</ScrollAnimation>
						<ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
							<div className="text-left">{piatto.description}</div>
						</ScrollAnimation> */}
					</MobileView>
				</div>
			</div>
		));
	};

	return (
		<>
			<section
				className="module"
				id="specialities"
				style={{ backgroundColor: "#f6f6f6" }}>
				{/* <div className="piatti-logo">
					<ScrollAnimation animateIn="fadeIn" delay={100}>
						<img src={badge} />
					</ScrollAnimation>
				</div> */}
				<div className="container">
					<div className="row">
						<div className="col-sm-2 col-sm-offset-5">
							<div className="alt-module-subtitle">
								<span className="holder-w"></span>
								<h5 className="font-serif">{piattiSection.subtitle}</h5>
								<span className="holder-w"></span>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-sm-8 col-sm-offset-2">
							<h2 className="module-title font-alt">{piattiSection.title}</h2>
						</div>
					</div>
					<div className="row multi-columns-row">{renderPiatti()}</div>
				</div>
			</section>
		</>
	);
};

export default Piatti;
