import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";

import { routes } from "routes.js";

import "./header.css";

import logo from "images/bi/rabarbaro_badge4_RGB_bianco blu.png";

let location;

const Header = ({ title }) => {
	const [scroll, setScroll] = useState(false);
	const [isOpen, setIsOpen] = useState(false);

	location = useLocation();

	useEffect(() => {
		window.addEventListener("scroll", () => {
			setScroll(window.scrollY > 50);
		});
	}, []);

	useEffect(() => {
		setIsOpen(false);
	}, [location]);

	const renderRoutes = () =>
		routes.map((route) => {
			// se è una route con figli
			if (route.visible) {
				return (
					<>
						<li key={route.key}>
							<Link to={route.route} key={route.key + "-link"}>
								{route.linkName}
							</Link>
						</li>
					</>
				);
			}

			return null;
		});

	return (
		<>
			<nav
				className={
					location.pathname?.indexOf("/home") > -1
						? scroll
							? "navbar navbar-custom navbar-fixed-top"
							: "navbar navbar-custom navbar-fixed-top navbar-transparent"
						: "navbar navbar-custom navbar-fixed-top"
				}
				role="navigation">
				<div className="container">
					<div className="navbar-header">
						<Link target="_self" to="https://tavernarabarbaro.com">
							<img className="logo" src={logo} alt={"Rabarbaro"} />
						</Link>
						<div className="menu-icon" onClick={() => setIsOpen(!isOpen)}>
							<i className={isOpen ? "fa fa-times" : "fa fa-bars"}></i>
						</div>
					</div>

					<div className={isOpen ? "" : "collapse navbar-collapse"}>
						<ul className="nav navbar-nav navbar-right">{renderRoutes()}</ul>
					</div>
				</div>
			</nav>
		</>
	);
};

export default Header;
