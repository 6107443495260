import React, { useEffect } from "react";
import Piatti from "components/Piatti";

import headerBg from "images/about_bg.jpg";

import { eventiPage, eventi } from "site-structure";

const Eventi = ({ title }) => {
	useEffect(() => {
		document.title = title;
	}, [title]);

	return (
		<>
			<section
				className="module module-big bg-dark-30"
				style={{
					backgroundImage: `url(${headerBg})`,
					backgroundSize: "cover",
					backgroundPosition: "50%",
				}}>
				<div className="container">
					<div className="row">
						<div className="col-sm-2 col-sm-offset-5">
							<div className="alt-module-subtitle">
								<span className="holder-w"></span>
								<h5 className="font-serif">{eventiPage.pageTitle}</h5>
								<span className="holder-w"></span>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-sm-8 col-sm-offset-2">
							<h2 className="module-title font-alt mb-0">
								{eventiPage.description}
							</h2>
						</div>
					</div>
				</div>
			</section>

			<section className="module">
				<div className="container">
					<div className="row">
						<div className="col-sm-8 col-sm-offset-2">
							<div className="row multi-columns-row mb-0">
								<div className="col-sm-6 col-md-6 col-lg-6">
									<div className="content-box">
										<div className="content-box-image">
											<img src={eventi[0].image} alt={eventi[0].name} />
										</div>
										<h3 className="content-box-title font-serif">
											{eventi[0].name}
										</h3>
										{eventi[0].description}
									</div>
								</div>
								<div className="col-sm-6 col-md-6 col-lg-6">
									<div className="content-box">
										<div className="content-box-image">
											<img src={eventi[1].image} alt={eventi[1].name} />
										</div>
										<h3 className="content-box-title font-serif">
											{eventi[1].name}
										</h3>
										{eventi[1].description}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<Piatti />
		</>
	);
};

export default Eventi;
