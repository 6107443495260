import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import Lottie from "react-lottie";

import mouseDown from "lottie/mouse-down";

import "./hero.scss";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import logo from "images/bi/rabarbaro_logoPayoff_RGB_bianco.png";
import video from "video/hero.mp4";

import { heroCarousel } from "site-structure";

const Hero = () => {
	const defaultLottieOptions = {
		loop: true,
		autoplay: true,
		animationData: mouseDown,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice",
		},
	};

	const owlCarouselRef = useRef(null);

	useEffect(() => {
		// Aggiungi un listener per l'evento popstate
		const handlePopstate = () => {
			// Riavvia Owl Carousel quando viene scatenato l'evento popstate
			if (owlCarouselRef.current) {
				owlCarouselRef.current.onInitialize();
			}
		};

		window.addEventListener("popstate", handlePopstate);

		// Cleanup: rimuovi il listener quando il componente viene smontato
		return () => {
			window.removeEventListener("popstate", handlePopstate);
		};
	}, []);

	return (
		<>
			<section className="home-section home-full-height" id="home">
				<div className="hero-slider">
					<div className="slides">
						{/* <OwlCarousel
							ref={owlCarouselRef}
							className="owl-theme"
							loop
							// style={{ height: "100%" }}
							items={1}
							autoplay
							autoplayTimeout={1000000}> */}
						<div className="item">
							<div
								className="bg-dark-30 restaurant-page-header bg-dark"
								style={{
									// backgroundImage: `url(${heroCarousel[0].image})`,
									// backgroundSize: "cover",
									// backgroundPosition: "50%",
									height: "100vh",
								}}>
								<video
									autoPlay
									loop
									muted
									height={"100vh"}
									width={"100%"}
									style={{
										position: "absolute",
										height: "100%",
										objectFit: "cover",
										zIndex: 0,
										left: 0,
										right: 0,
										top: 0,
										left: 0,
									}}>
									<source src={video} type="video/mp4" />
									Your browser does not support the video tag.
								</video>
								<div
									className="titan-caption"
									style={{
										zIndex: 1,
										backgroundColor: "rgba(0, 0, 0, .5)",
									}}>
									<div className="caption-content">
										<div className="font-alt mb-30 titan-title-size-1">
											{" "}
											{heroCarousel[0].subtitle}
											{/* Chiusi per ferie fino al 3 Settembre. */}
										</div>
										<div className="font-alt mb-40 titan-title-size-4">
											<img src={logo} className="logo-hero" />
										</div>
										<a
											className="section-scroll btn btn-big btn-theme btn-round"
											target="_blank"
											rel="noreferrer"
											href={heroCarousel[0].action}>
											{heroCarousel[0].callToAction}
										</a>
									</div>
								</div>
							</div>
						</div>
						{/* <div className="item">
								<div
									className="bg-dark-30 restaurant-page-header bg-dark"
									style={{
										backgroundImage: `url(${heroCarousel[0].image})`,
										backgroundSize: "cover",
										backgroundPosition: "50%",
										height: "100vh",
									}}>
									<div className="titan-caption">
										<div className="caption-content">
											<div className="font-alt mb-30 titan-title-size-1">
												{" "}
												{heroCarousel[0].subtitle}
											</div>
											<div className="font-alt mb-40 titan-title-size-4">
												<img src={logo} className="logo-hero" />
											</div>
											<a
												className="section-scroll btn btn-big btn-theme btn-round"
												target="_blank"
												rel="noreferrer"
												href={heroCarousel[0].action}>
												{heroCarousel[0].callToAction}
											</a>
										</div>
									</div>
								</div>
							</div>
							<div className="item">
								<div
									className="bg-dark-30 restaurant-page-header bg-dark"
									style={{
										backgroundImage: `url(${heroCarousel[1].image})`,
										backgroundSize: "cover",
										height: "100vh",
									}}>
									<div className="titan-caption">
										<div className="caption-content">
											<div className="font-alt mb-30 titan-title-size-1">
												{" "}
												{heroCarousel[1].subtitle}
											</div>
											<div className="font-alt mb-40 titan-title-size-4">
												<img src={logo} className="logo-hero" />
											</div>
											<a
												className="section-scroll btn btn-big btn-theme btn-round"
												target="_blank"
												rel="noreferrer"
												href={heroCarousel[1].action}>
												{heroCarousel[1].callToAction}
											</a>
										</div>
									</div>
								</div>
							</div> */}
						{/* </OwlCarousel> */}
					</div>
					<div className="mouse-down">
						<div>
							<Lottie options={defaultLottieOptions} height={60} width={60} />
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Hero;
