import React from "react";

import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.compat.css";

import { serviziSection, servizi } from "site-structure";

const Servizi = () => {
	const renderServices = () =>
		servizi.map((servizio) => (
			<>
				<div className="col-sm-12 col-md-6 col-lg-6" key={servizio.key + "-section"}>
					<div className="features-item">
						<div className="content-box">
							<div className="content-box-image">
								<ScrollAnimation
									animateIn={servizio.animation}
									animateOnce={true}
									key={servizio.key + "-section-1"}>
									<img src={servizio.image} alt={servizio.name} />
								</ScrollAnimation>
							</div>
						</div>

						<ScrollAnimation
							animateIn={servizio.animation}
							delay={0}
							animateOnce={true}
							key={servizio.key + "-section-2"}>
							<h3
								className="features-title font-alt"
								dangerouslySetInnerHTML={{ __html: servizio.name }}></h3>
						</ScrollAnimation>

						{/* <ScrollAnimation
							animateIn={servizio.animation}
							delay={100}
							animateOnce={true}
							key={servizio.key + "-section-3"}>
							<h4>{servizio.when}</h4>
						</ScrollAnimation> */}

						<ScrollAnimation
							animateIn={servizio.animation}
							delay={200}
							animateOnce={true}
							key={servizio.key + "-section-4"}>
							{servizio.description}
						</ScrollAnimation>
					</div>
				</div>
			</>
		));

	return (
		<>
			<section className="module" id="services">
				<div className="container">
					<div className="row">
						<div className="col-sm-2 col-sm-offset-5">
							<div className="alt-module-subtitle">
								<span className="holder-w"></span>
								<h5 className="font-serif">{serviziSection.subtitle}</h5>
								<span className="holder-w"></span>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-sm-8 col-sm-offset-2">
							<h2 className="module-title font-alt">{serviziSection.title}</h2>
						</div>
					</div>
					<div className="row multi-columns-row">{renderServices()}</div>
				</div>
			</section>
		</>
	);
};

export default Servizi;
