// src/components/Loader.js
import React from "react";
import "./loader.css"; // Assicurati di creare un file CSS per il logo rotante

import roundedLogo from "images/bi/rabarbaro_badge1_RGB_bianco_blu.png";

const Loader = () => {
	return (
		<div className="loader-container">
			<div
				className="spinner"
				style={{ backgroundImage: `url(${roundedLogo})` }}></div>
		</div>
	);
};

export default Loader;
