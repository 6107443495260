import React, { useEffect } from "react";

import Hero from "components/Hero";
import Servizi from "components/Servizi";
import Piatti from "components/Piatti";
import Reviews from "components/Reviews";

const Home = ({ title }) => {
	useEffect(() => {
		document.title = title;
	}, [title]);

	return (
		<>
			<Hero />

			<div className="main">
				<Servizi />

				<Piatti />

				{/* <Reviews home={true} /> */}
			</div>
		</>
	);
};

export default Home;
