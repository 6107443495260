import React from "react";

import team from "images/team.jpg";

const Team = () => {
	return (
		<>
			<section className="module" style={{ paddingTop: "0" }}>
				<div className="container">
					{/* <div className="row">
						<div className="col-sm-2 col-sm-offset-5">
							<div className="alt-module-subtitle">
								<span className="holder-w"></span>
								<h5 className="font-serif">Il nostro team</h5>
								<span className="holder-w"></span>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-sm-8 col-sm-offset-2">
							<h2 className="module-title font-alt">
								Chi lavora per darti il meglio
							</h2>
						</div>
					</div> */}
					<div
						className="row multi-columns-row"
						style={{ display: "flex", justifyContent: "space-between" }}>
						<div className="col-sm-12 col-md-6 col-lg-6">
							<div className="features-item">
								<div className="content-box">
									<div className="content-box-image">
										<img src={team} alt={process.env.REACT_APP_WEBSITE_NAME} />
									</div>
								</div>

								<h4>Il team di {process.env.REACT_APP_WEBSITE_NAME}</h4>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Team;
