import React from "react";
import { BrowserView, MobileView } from "react-device-detect";

import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.compat.css";

import { reviewsSection, reviews } from "site-structure";

const index = ({ home = false }) => {
	const renderReviews = () => {
		return reviews.map((review, index) => (
			<div className="col-sm-6 col-md-3 col-lg-3">
				<BrowserView>
					<ScrollAnimation
						animateIn="fadeInLeft"
						delay={150 * index}
						duration="1.2">
						<div className="post mb-20">
							<div className="post-header">
								<div className="post-meta">
									Da&nbsp;
									<strong style={{ color: "#111" }}>{review.author}</strong>
									&nbsp;|&nbsp;
									{review.date}
								</div>
							</div>
							<div className="post-entry">
								<p>{review.description}</p>
							</div>
						</div>
					</ScrollAnimation>
				</BrowserView>

				<MobileView>
					<ScrollAnimation animateIn="fadeInLeft" duration="1.2">
						<div className="post mb-20">
							<div className="post-header">
								<div className="post-meta">
									Da&nbsp;
									<strong style={{ color: "#111" }}>{review.author}</strong>
									&nbsp;|&nbsp;
									{review.date}
								</div>
							</div>
							<div className="post-entry">
								<p>{review.description}</p>
							</div>
						</div>
					</ScrollAnimation>
				</MobileView>
			</div>
		));
	};

	return (
		<>
			<section
				className="module"
				id="news"
				style={
					home === false ? { backgroundColor: "rgb(246, 246, 246)" } : null
				}>
				<div className="container">
					<div className="row">
						<div className="col-sm-2 col-sm-offset-5">
							<div className="alt-module-subtitle">
								<span className="holder-w"></span>
								<h5 className="font-serif">{reviewsSection.subtitle}</h5>
								<span className="holder-w"></span>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-sm-8 col-sm-offset-2">
							<h2 className="module-title font-alt">{reviewsSection.title}</h2>
						</div>
					</div>
					<div className="row multi-columns-row post-columns">
						{renderReviews()}
					</div>
				</div>
			</section>
		</>
	);
};

export default index;
