import React, { useEffect } from "react";

import headerBg from "images/contatti_bg.jpg";

import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import { contattiPage, contatti } from "site-structure";

const Contatti = ({ title }) => {
	const ref = React.createRef();

	useEffect(() => {
		document.title = title;
	}, []);

	const onSubmit = (mailMessage) => {
		window.location.href = `mailTo:${contatti.email}?Contatto da sito a nome ${mailMessage.sender}&body=${mailMessage.text}`;
	};

	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({
		defaultValues: {
			sender: "",
			text: "",
		},
	});

	return (
		<div className="main">
			<section
				className="module module-big parallax-bg bg-dark-30"
				style={{
					backgroundImage: `url(${headerBg})`,
					backgroundSize: "cover",
					backgroundPosition: "50%",
				}}>
				<div className="container">
					<div className="row">
						<div className="col-sm-2 col-sm-offset-5">
							<div className="alt-module-subtitle">
								<span className="holder-w"></span>
								<h5 className="font-serif">{contattiPage.pageTitle}</h5>
								<span className="holder-w"></span>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-sm-8 col-sm-offset-2">
							<h2 className="module-title font-alt mb-0">
								{contattiPage.description}
							</h2>
						</div>
					</div>
				</div>
			</section>
			<section className="module module-big" style={{ paddingTop: "60px" }}>
				<div className="container">
					<div className="row">
						<div className="col-sm-6">
							<h4 className="font-alt">Scrivici</h4>
							<br />
							<form id="contactForm">
								<div className="form-group">
									<Controller
										key="sender"
										name="sender"
										control={control}
										rules={{
											required: "La email è obbligatoria",
											pattern: {
												value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
												message: "Formato indirizzo email errato.",
											},
										}}
										render={({ field }) => (
											<input
												className="form-control"
												type="email"
												id="email"
												name="email"
												placeholder="La tua email *"
												ref={ref}
												{...field}
											/>
										)}
									/>
									<ErrorMessage
										key="senderError"
										errors={errors}
										name="sender"
										render={({ message }) => (
											<p className="help-block text-danger">{message}</p>
										)}
									/>
								</div>
								<div className="form-group">
									<Controller
										key="text"
										name="text"
										control={control}
										render={({ field }) => (
											<textarea
												className="form-control"
												rows="7"
												id="message"
												name="message"
												placeholder="Il tuo messaggio"
												ref={ref}
												{...field}></textarea>
										)}
									/>
								</div>
								<div className="text-center">
									<button
										ref={ref}
										className="btn btn-block btn-round btn-d mb-10"
										id="cfsubmit"
										type="submit"
										onClick={handleSubmit(onSubmit)}>
										Invia
									</button>
									oppure
									<a
										href="https://api.whatsapp.com/send?phone=3335468741&amp;text=Ciao,mi serve un'informazione."
										className="btn btn-theme btn-round mt-10"
										target="_blank"
										rel="noreferrer"
										style={{ width: "100%" }}>
										Contattaci su WhatsApp
									</a>{" "}
								</div>
							</form>
							<div
								className="ajax-response font-alt"
								id="contactFormResponse"></div>
						</div>
						<div className="col-sm-6">
							<h4 className="font-alt">Orari e dove trovarci</h4>
							<p>
								Piazza Cittadella 9, Piacenza. Pieno centro, fronte Palazzo
								Farnese
							</p>
							<hr style={{ marginTop: 0 }} />
							<h4 className="font-alt">Aperti da Martedì a Sabato.</h4>
							<ul className="list-unstyled">
								<li>
									Smart Lunch da Martedì a Venerdì, dalle 12:00 alle 14:00.
								</li>
								<li>
									A là carte da Martedì a Sabato, dalle 19:30 alle 22:30 e il
									Sabato a pranzo.
								</li>
							</ul>
						</div>
					</div>
				</div>
			</section>
			<div style={{ width: "100%" }}>
				<iframe
					title="map"
					width="100%"
					height="600"
					frameBorder="0"
					marginHeight="0"
					marginWidth="0"
					loading="async"
					src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Piazza%20Cittadella%209,%20Piacenza+(Taverna%20Rabarbaro)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
			</div>
		</div>
	);
};

export default Contatti;
