import React, { useEffect } from "react";
import Team from "components/Team";
import Reviews from "components/Reviews";

import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.compat.css";

import headerBg from "images/storia_bg.jpg";

import { storiaPage, storia } from "site-structure";

import "./storia.scss";

const Storia = ({ title }) => {
	useEffect(() => {
		document.title = title;
	}, [title]);

	return (
		<div className="main">
			<section
				className="module module-big parallax-bg bg-dark-30"
				style={{
					backgroundImage: `url(${headerBg})`,
					backgroundSize: "cover",
					backgroundPosition: "50%",
				}}>
				<div className="container">
					<div className="row">
						<div className="col-sm-2 col-sm-offset-5">
							<div className="alt-module-subtitle">
								<span className="holder-w"></span>
								<h5 className="font-serif">{storiaPage.pageTitle}</h5>
								<span className="holder-w"></span>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-sm-8 col-sm-offset-2">
							<h2 className="module-title font-alt mb-0">
								{storiaPage.description}
							</h2>
						</div>
					</div>
				</div>
			</section>
			<section className="module">
				<div className="container storia">
					<div className="row">
						<div className="post">
							<div class="post-header font-alt">
								<h2 class="post-title">Da dove arriviamo</h2>
							</div>
							<div class="post-entry">
								<p>{storia.text}</p>
							</div>
						</div>
					</div>

					<Team />

					<div className="row multi-columns-row">
						<div className="col-sm-12">
							<ScrollAnimation
								animateIn="fadeInDown"
								delay={0}
								animateOnce={true}>
								<div className="menu">
									<div className="row">
										<div className="col-sm-8">
											<h4 className="menu-title font-alt">
												Apertura lavanderia
											</h4>
											<div className="menu-detail">
												Nasce la lavanderia Everest del signor Paolo
											</div>
										</div>
										<div className="col-sm-4 menu-price-detail">
											<h4 className="menu-price font-alt">1970</h4>
										</div>
									</div>
								</div>
							</ScrollAnimation>
							<ScrollAnimation
								animateIn="fadeInDown"
								delay={200}
								animateOnce={true}>
								<div className="menu">
									<div className="row">
										<div className="col-sm-8">
											<h4 className="menu-title font-alt">
												Chiusura lavanderia
											</h4>
											<div className="menu-detail">
												Dopo 30 anni di servizio, Everest chiude
											</div>
										</div>
										<div className="col-sm-4 menu-price-detail">
											<h4 className="menu-price font-alt">2000</h4>
										</div>
									</div>
								</div>
							</ScrollAnimation>
							<ScrollAnimation
								animateIn="fadeInDown"
								delay={400}
								animateOnce={true}>
								<div className="menu">
									<div className="row">
										<div className="col-sm-8">
											<h4 className="menu-title font-alt">
												Nasce il Rabarbaro
											</h4>
											<div className="menu-detail">
												Iniziano i lavori per la costruzione di Taverna
												Rabarbaro
											</div>
										</div>
										<div className="col-sm-4 menu-price-detail">
											<h4 className="menu-price font-alt">Maggio 2014</h4>
										</div>
									</div>
								</div>
							</ScrollAnimation>
							<ScrollAnimation
								animateIn="fadeInDown"
								delay={600}
								animateOnce={true}>
								<div className="menu">
									<div className="row">
										<div className="col-sm-8">
											<h4 className="menu-title font-alt">Apre Rabarbaro</h4>
											<div className="menu-detail">
												Taverna Rabarbaro apre ai suoi clienti
											</div>
										</div>
										<div className="col-sm-4 menu-price-detail">
											<h4 className="menu-price font-alt">Ottobre 2014</h4>
										</div>
									</div>
								</div>
							</ScrollAnimation>
							<ScrollAnimation
								animateIn="fadeInDown"
								delay={800}
								animateOnce={true}>
								<div className="menu">
									<div className="row">
										<div className="col-sm-8">
											<h4 className="menu-title font-alt">
												Menzione su Gambero Rosso
											</h4>
											<div className="menu-detail">
												Siamo citati ogni anno dalla guida di Ristoranti in
												Italia che descrive le eccellenze della ristorazione
											</div>
										</div>
										<div className="col-sm-4 menu-price-detail">
											<h4 className="menu-price font-alt">2017</h4>
										</div>
									</div>
								</div>
							</ScrollAnimation>
						</div>
					</div>
				</div>
			</section>

			<Reviews />
		</div>
	);
};

export default Storia;
